<template>
  <div class="fix-height home__top-banner">
    <div class="hero-banner">
      <Swiper ref="banner" v-bind="swiperOption" class="mySwiper">
        <SwiperSlide v-if="bannerJackpot" class="swiper-slide" :data-swiper-autoplay="15000" @click="navigationCheckLoggedInAndOpenGame(bannerJackpot)">
          <BannerJackpot :item="bannerJackpot" />
        </SwiperSlide>
        <SwiperSlide v-for="(item, key) in bannerData" :key="key" class="swiper-slide">
          <BannerItem :item="item" :index="key" @click="handleClickBanner(item)" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, EffectCoverflow, Navigation } from 'swiper'
import BannerItem from '~/components/desktop/pages/home/banner/banner-item.vue'
import useHeroBanner from '~/composables/home/useHeroBanner'
import { useAppStore } from '~/store/app'
import BannerJackpot from '~/components/desktop/pages/home/banner/banner-jackpot.vue'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { jackpot } = storeToRefs(store)
const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const { fetchBanner, handleClickBanner } = useHeroBanner()
const swiperOption = reactive({
  grabCursor: true,
  initialSlide: 0,
  observer: true,
  loop: true,
  slidesPerView: 1,
  slidesPerGroup: 1,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false
  },
  pagination: {
    clickable: true
  },
  navigation: {
    nextEl: '.custom-next',
    prevEl: '.custom-prev'
  },
  centeredSlides: true,
  spaceBetween: 0,
  modules: [Autoplay, Pagination, EffectCoverflow, Navigation]
})

const { data: bannerData } = await useAsyncData('banner', async () => {
  return await fetchBanner()
})
const isMounted = ref(false)

const bannerJackpot = computed(() => {
  return isMounted.value ? (jackpot.value.banner_jackpot?.[0] || null) : { partner_provider: 'go', name: 'Tài Xỉu', jackpot: 1000 }
})

onMounted(() => {
  isMounted.value = true
})

</script>

<style scoped lang="scss" src="public/assets/scss/components/desktop/pages/home/banner/index.scss"></style>
